import React, { useRef } from 'react'
import SwiperCore, { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Row } from '../../../layout/Grid'
import DoubleTitle from '../../DoubleTitle'
import * as Styled from './SectionRecruitmentProcess.styles'
import IconAnaliza from '../../../images/kariera/analiza.svg'
import IconKontakt from '../../../images/kariera/kontakt.svg'
import IconRozmowa from '../../../images/kariera/rozmowa.svg'
import IconInformacja from '../../../images/kariera/informacja.svg'
import IconPropozycja from '../../../images/kariera/propozycja.svg'
import IconPowitanie from '../../../images/kariera/powitanie.svg'

// Install modules
SwiperCore.use([Mousewheel])

export const SectionRecruitmentProcess = () => {
  const swiper = useRef()

  const renderSlide = (icon, title, text) => (
    <SwiperSlide tag="li">
      {icon}
      <Styled.ContentWrapper>
        <p className="lead">{title}</p>
        {text && <p>{text}</p>}
      </Styled.ContentWrapper>
    </SwiperSlide>
  )

  return (
    <Styled.Section id="czytaj-wiecej">
      <Row column>
        <DoubleTitle
          smallTitle="Kariera"
          title="Proces Rekrutacji"
          border="addon"
          center
        />
        <Swiper
          className="mobile-disabled"
          spaceBetween={0}
          slidesPerView={6}
          noSwiping
          mousewheel={{
            releaseOnEdges: 'true',
          }}
          breakpoints={{
            1024: {
              slidesPerView: 3,
            },
          }}
          wrapperTag="ul"
          ref={swiper}
        >
          {renderSlide(
            <IconAnaliza />,
            <>
              Analizujemy <br />
              twoje CV
            </>,
            'Sprawdzamy, czy spełniasz podstawowe wymagania oferty'
          )}
          {renderSlide(
            <IconKontakt />,
            <>
              Kontaktujemy <br />
              się z tobą
            </>,
            'Proponujemy termin rozmowy kwalifikacyjnej'
          )}
          {renderSlide(
            <IconRozmowa />,
            <>
              Rozmowa <br />z rekruterem
            </>,
            <>
              Zadamy ci kilka pytań, ale również opowiemy ci jak wygląda praca
              w&nbsp;Nordicu
            </>
          )}
          {renderSlide(
            <IconInformacja />,
            <>
              Informacja <br />
              zwrotna
            </>,
            'Otrzymasz od nas informację zwrotną niezależnie od tego jaka to będzie decyzja'
          )}
          {renderSlide(
            <IconPropozycja />,
            <>
              Propozycja <br />
              współpracy
            </>,
            'Jeśli będziemy na tak, zaproponujmy Ci warunki współpracy '
          )}
          {renderSlide(
            <IconPowitanie />,
            <>Witaj na&nbsp;pokładzie nordica!</>
          )}
        </Swiper>
      </Row>
    </Styled.Section>
  )
}
