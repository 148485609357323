import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 24px 0;
  text-align: center;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 40px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 60px 0;
  }

  h2 + .lead {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      max-width: 66.6%;
      margin: 0 auto 1rem;
    }
  }

  .swiper {
    counter-reset: listCounter;
    overflow: visible;
  }

  .swiper-slide {
    counter-increment: listCounter;
    margin-top: 40px;

    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      width: 100% !important;
    }

    &:last-child > div:after {
      content: none;
    }

    p {
      text-transform: uppercase;
      text-align: left;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        font-size: 12px;
      }
    }

    .lead {
      position: relative;

      @media (max-width: ${props => props.theme.breakpoints.md}) {
        font-size: 16px;
      }

      &:before {
        content: '0' counter(listCounter);
        color: ${props => props.theme.colors.dark};
        font-size: 50px;
        font-weight: 500;
        position: absolute;
        left: -80px;
        top: -32px;

        @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
          top: -10px;
        }

        @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
          font-size: 70px;
          left: -120px;
        }
      }
    }

    svg {
      height: 124px;
      width: auto;
      margin: 0 auto;

      @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
        height: 240px;
      }

      @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
        height: 300px;
      }
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
  padding: 40px 0 40px 80px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    margin-top: 40px;
    padding-top: 60px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      background-color: ${props => props.theme.colors.primary};
    }

    &:before {
      top: 0;
      left: calc(50% - 10px);
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    &:after {
      top: 9px;
      left: 50%;
      height: 2px;
      width: 100%;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding-left: 120px;
  }
`

export { Section, ContentWrapper }
