import styled, { css } from 'styled-components'
import { BgImage } from 'gbimage-bridge'

const Section = styled(BgImage)`
  position: relative;
  background-color: ${props => props.theme.colors.light};
  padding-top: 40px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding-top: 80px;
  }

  .swiper-wrapper {
    align-items: flex-end;
  }

  button {
    position: absolute;
    left: 3%;
    top: 75%;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      top: 50%;
    }

    & + button {
      left: auto;
      right: 3%;
    }
  }
`

const ImageWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    &:before,
    &:after {
      background-size: contain !important;
      background-position: bottom !important;
    }
  }
`

const ContentWrapper = styled.div`
  position: relative;
  padding: 80px 0 40px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding-top: 120px;
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding-top: 160px;
  }

  &:before {
    content: '';
    height: 64px;
    width: 64px;
    background: transparent
      url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMzUuNiIgaGVpZ2h0PSIxMDYuOCIgdmlld0JveD0iMCAwIDEzNS42IDEwNi44Ij4KICA8cGF0aCBkPSJNNTguNC0yNDYuNHExMy4yLDguNCwxMy4yLDI1LjIsMCwxMy42LTguOCwyMi40dC0yMiw4LjhhMjkuNiwyOS42LDAsMCwxLTIxLjItOC40cS04LjgtOC40LTguOC0yMi44YTMzLjcyNiwzMy43MjYsMCwwLDEsMi0xMS44LDExMy42NDQsMTEzLjY0NCwwLDAsMSw2LTEzbDI4LjQtNTAuOGgzMFptNjkuMiwwcTEzLjIsOC40LDEzLjIsMjUuMiwwLDEzLjYtOC44LDIyLjRUMTEwLTE5MGEyOS42LDI5LjYsMCwwLDEtMjEuMi04LjRRODAtMjA2LjgsODAtMjIxLjJBMzMuNzI2LDMzLjcyNiwwLDAsMSw4Mi0yMzNhMTEzLjY0NCwxMTMuNjQ0LDAsMCwxLDYtMTNsMjguNC01MC44aDMwWiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTEwLjggMjk2LjgpIiBmaWxsPSIjNDI2ZDliIi8+Cjwvc3ZnPgo=')
      no-repeat center / contain;
    position: absolute;
    left: 0;
    top: 0;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      height: 80px;
      width: 80px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      height: 120px;
      width: 120px;
    }
  }
`

const Quote = styled.p`
  color: ${props => props.theme.colors.dark};
  font-style: italic;
  font-weight: 300;
  line-height: 1.4;
`

const Name = styled.p`
  color: ${props => props.theme.colors.dark};
  font-size: 26px;
  font-weight: 500;
  margin: 1em 0 0.75em;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    font-size: 36px;
  }
`

const Job = styled.p`
  text-transform: uppercase;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    margin-bottom: 40px;
  }
`

export { Section, ImageWrapper, ContentWrapper, Quote, Name, Job }
