import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import * as Styled from './SectionTeam.styles'
import { Column, Row } from '../../../layout/Grid'
import DoubleTitle from '../../DoubleTitle'

export const SectionTeam = () => {
  return (
    <Styled.Section>
      <Row alignMiddle>
        <Column xs={12} lg={7} largeOrder={2} noPaddingMobile>
          <Styled.ImageWrapper>
            <StaticImage src="../../../images/kariera/team1.jpg" alt="" />
            <StaticImage src="../../../images/kariera/team2.jpg" alt="" />
            <StaticImage src="../../../images/kariera/team3.jpg" alt="" />
            <StaticImage src="../../../images/kariera/team4.jpg" alt="" />
          </Styled.ImageWrapper>
        </Column>
        <Column xs={12} lg={5}>
          <DoubleTitle
            smallTitle="Kariera"
            title={
              <>
                Nasz <br />
                Zespół
              </>
            }
            border="addon"
          />
          {/* <p className="lead"> */}
          {/*  Kluczem do sukcesu są ludzie, to oni tworzą najwyższe standardy */}
          {/*  i&nbsp;dokonują rzeczy niemalże niemożliwych. Wśród nas są ludzie */}
          {/*  z&nbsp;doświadczeniem, ale i&nbsp;tacy, którzy spedycji nauczyli się */}
          {/*  od najlepszych. */}
          {/* </p> */}
          <p>
            <b>Jesteśmy</b>
            <ul>
              <li>Ambitni, cierpliwi i kreatywni,</li>
              <li>Zawsze angażujemy się we wszystko, co robimy,</li>
              <li>Dzielimy się wiedzą i pomysłami,</li>
              <li>Lubimy pracować w zespole,</li>
              <li>Otwarci na siebie i na nowatorskie rozwiązania.</li>
            </ul>
          </p>
        </Column>
      </Row>
    </Styled.Section>
  )
}
