import React, { useRef, useState } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Row, Column } from '../../../layout/Grid'
import * as Styled from './SectionOpinionsSlider.styles'
import * as StyledSwiper from '../../Swiper/Swiper.styles'

// Install modules
SwiperCore.use([Navigation, Pagination])

export const SectionOpinionsSlider = ({ bgImage, items }) => {
  const swiper = useRef()

  if (items.length === 0) return null

  return (
    <Styled.Section Tag="section" image={bgImage}>
      <Row column>
        <Swiper
          spaceBetween={20}
          slidesPerView={1}
          noSwiping
          wrapperTag="ul"
          ref={swiper}
        >
          {items.map(item => (
            <SwiperSlide tag="li" key={item.name}>
              <Row alignBottom alignJustify>
                <Column xs={12} lg={6} xl={5} largeOrder={2}>
                  <Styled.ContentWrapper>
                    <Styled.Quote className="lead">{item.content}</Styled.Quote>
                    <Styled.Name>{item.name}</Styled.Name>
                    <Styled.Job className="lead">{item.job}</Styled.Job>
                    <a
                      href="https://www.gowork.pl/opinie_czytaj,21593988"
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      <StaticImage
                        src="../../../images/kariera/gowork.png"
                        alt=""
                      />
                    </a>
                  </Styled.ContentWrapper>
                </Column>
                <Column xs={12} lg={6}>
                  <Styled.ImageWrapper>{item.image}</Styled.ImageWrapper>
                </Column>
              </Row>
            </SwiperSlide>
          ))}
        </Swiper>
      </Row>
      <StyledSwiper.CustomPrevButton
        onClick={() => swiper.current.swiper.slidePrev()}
        type="button"
        aria-label="Poprzedni slajd"
        white
      />
      <StyledSwiper.CustomNextButton
        onClick={() => swiper.current.swiper.slideNext()}
        type="button"
        aria-label="Następny slajd"
        white
      />
    </Styled.Section>
  )
}
