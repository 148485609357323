import styled, { css } from 'styled-components'

const Section = styled.section`
  position: relative;
  padding: 16px 0 32px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 120px 0 40px;

    .lead {
      margin-bottom: 40px;
    }
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 200px 0 60px;
  }
`

const ImageWrapper = styled.div`
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    margin-left: -${props => props.theme.grid.sideMargin};
    margin-right: -${props => props.theme.grid.sideMargin};
    text-align: right;
  }

  .gatsby-image-wrapper {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      &:first-of-type {
        margin-left: 25%;
      }

      &:nth-child(3) {
        width: 40%;
        position: absolute;
        left: -10%;
        top: 2%;
        z-index: 1;
      }

      &:nth-child(4) {
        width: 50%;
        position: absolute;
        right: -10%;
        bottom: 7%;
      }
    }
    @media (max-width: ${props => props.theme.breakpoints.lg}) {
      &:nth-child(3) {
        width: 50%;
      }
      &:nth-child(4) {
        display: none;
      }
    }
    &:nth-child(2) {
      margin: -40px 0 40px;
      width: 50%;
    }
  }

  // .gatsby-image-wrapper {
  //   position: absolute;
  //   left: -${props => props.theme.grid.sideMargin};
  //   right: -${props => props.theme.grid.sideMargin};
  //   top: 0;
  //   bottom: 0;
  //
  //   @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
  //     left: 0;
  //     right: 0;
  //   }
  // }
`

export { Section, ImageWrapper }
