import React, { useRef, useState } from 'react'
import { getImage } from 'gatsby-plugin-image'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Row, Column } from '../../../layout/Grid'
import DoubleTitle from '../../DoubleTitle'
import Button from '../../Button'
import * as Styled from './SectionCareerSlider.styles'
import * as StyledSwiper from '../../Swiper/Swiper.styles'

// Install modules
SwiperCore.use([Navigation, Pagination])

export const SectionCareerSlider = ({ items }) => {
  const swiper = useRef()
  const [activeSlide, setActiveSlide] = useState(0)

  if (items.length === 0) return null

  return (
    <Styled.Section multipleOffers={items.length > 1} number={activeSlide + 1}>
      <Row alignBottom alignJustify>
        <Column xs={12} lg={6} xl={5} largeOrder={2}>
          <DoubleTitle
            smallTitle="Kariera"
            title="Aktualne oferty&nbsp;pracy"
            border="addon"
          />
          <p className="lead show-for-large">
            Nieustannie się rozwijamy, dlatego do naszego zespoły poszukujemy
            kandydatów, którzy otwarci są na zmiany, a&nbsp;jednocześnie
            poszukują stabilnego zatrudnienia. Zachęcamy do zapoznania się
            z&nbsp;aktualnymi ofertami, a&nbsp;o&nbsp;szczegółach porozmawiamy
            na rozmowie rekrutacyjnej.
          </p>
          <Styled.ActionWrapper>
            <Button scroll="#aplikuj" primary="true">
              Aplikuj
            </Button>
            {items.length > 1 && (
              <Styled.SwiperActionWrapper>
                <span>
                  <StyledSwiper.CustomPrevButton
                    onClick={() => swiper.current.swiper.slidePrev()}
                    type="button"
                    aria-label="Poprzedni slajd"
                    white
                  />
                  <StyledSwiper.CustomNextButton
                    onClick={() => swiper.current.swiper.slideNext()}
                    type="button"
                    aria-label="Następny slajd"
                    white
                  />
                </span>
                <Styled.SwiperPagination>
                  <Styled.SwiperPaginationCurrent>
                    0{activeSlide + 1}&nbsp;
                  </Styled.SwiperPaginationCurrent>{' '}
                  &nbsp;0{items.length}
                </Styled.SwiperPagination>
              </Styled.SwiperActionWrapper>
            )}
          </Styled.ActionWrapper>
        </Column>
        <Column xs={12} lg={6}>
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            noSwiping
            wrapperTag="ul"
            initialSlide={activeSlide}
            onSlideChange={el => setActiveSlide(el.activeIndex)}
            ref={swiper}
          >
            {items.map(item => (
              <SwiperSlide tag="li" key={item.node.frontmatter.slug}>
                <Styled.ImageWrapper>
                  <Styled.Image
                    image={getImage(item.node.frontmatter.thumbnail)}
                    style={{ position: 'absolute' }}
                  />
                </Styled.ImageWrapper>
                <Styled.TitleWrapper>
                  <Styled.Title>{item.node.frontmatter.title}</Styled.Title>
                  {/* <Styled.City className="lead"> */}
                  {/*  Miejsce pracy: Bydgoszcz */}
                  {/* </Styled.City> */}
                  <Button
                    page={`/kariera/${item.node.frontmatter.slug}`}
                    primary="true"
                  >
                    Zobacz ofertę
                  </Button>
                </Styled.TitleWrapper>
              </SwiperSlide>
            ))}
          </Swiper>
        </Column>
      </Row>
    </Styled.Section>
  )
}
