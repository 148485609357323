import styled, { css } from 'styled-components'
import { BgImage } from 'gbimage-bridge'

const Section = styled.section`
  position: relative;
  background-color: ${props => props.theme.colors.light};
  padding: 50px 0 90px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    padding: 120px 0;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    padding: 160px 0;
  }

  &:after {
    content: '0${props => props.number}';
    position: absolute;
    font-size: 120px;
    line-height: 0.75;
    color: transparent;
    font-weight: 600;
    top: 165px;
    left: -40px;
    z-index: 1;
    -webkit-text-stroke: 3px #fff;

    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      font-size: 200px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
      left: -60px;
      top: 100px;
      font-size: 450px;
    }
  }

  ${props =>
    props.multipleOffers &&
    css`
      @media (max-width: ${props.theme.breakpoints.lg}) {
        padding-bottom: 160px;
      }
    `}

  h2 + .lead {
    @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
      margin-bottom: 40px;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 100%;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      top: 50%;
      background-image: linear-gradient(
        transparent,
        ${props => props.theme.colors.secondary}
      );
    }
  }
`

const Image = styled(BgImage)`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`
const TitleWrapper = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    position: absolute;
    left: 24px;
    bottom: 24px;
    right: 24px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    left: 32px;
    bottom: 40px;
    right: 32px;

    a {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
`

const Title = styled.h3`
  text-transform: uppercase;
  margin: 24px 0 16px;
  color: ${props => props.theme.colors.primary};
  font-size: 26px;

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    color: #fff;
    font-size: 32px;
  }
  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    font-size: 50px;
  }
`

const City = styled.p`
  color: ${props => props.theme.colors.dark};

  @media (min-width: ${props => props.theme.breakpoints.lgUp}) {
    color: ${props => props.theme.colors.primaryHover};
  }

  @media (min-width: ${props => props.theme.breakpoints.xxlUp}) {
    margin-bottom: 0;
  }
`

const ActionWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    position: absolute;
    left: calc(${props => props.theme.grid.sideMargin} + 20px);
    right: calc(${props => props.theme.grid.sideMargin} + 20px);
    bottom: 24px;
  }
`

const SwiperActionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;

  button {
    margin-right: 16px;
  }
`

const SwiperPagination = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: ${props => props.theme.colors.dark};
  letter-spacing: 0.1em;
`

const SwiperPaginationCurrent = styled.div`
  display: inline-block;
  position: relative;
  padding-right: 48px;

  &:after {
    content: '';
    width: 48px;
    height: 2px;
    background-color: ${props => props.theme.colors.primary};
    position: absolute;
    right: 0;
    bottom: 6px;
  }
`

export {
  Section,
  ImageWrapper,
  Image,
  TitleWrapper,
  Title,
  City,
  ActionWrapper,
  SwiperActionWrapper,
  SwiperPagination,
  SwiperPaginationCurrent,
}
